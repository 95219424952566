import React, { useState, useEffect } from 'react';
import { generateEnhancedBlogPost } from '../services/openai_blog';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { ChevronDown, ChevronUp, X } from 'lucide-react';

function GenerateBlog() {
  const [mainTopic, setMainTopic] = useState('');
  const [audience, setAudience] = useState('');
  const [tone, setTone] = useState('');
  const [length, setLength] = useState('');
  const [generatedBlog, setGeneratedBlog] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [language, setLanguage] = useState('EN/US');
  const [isGenerating, setIsGenerating] = useState(false);
  const [saveMessage, setSaveMessage] = useState('');
  const [businessInfo, setBusinessInfo] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [currentKeyword, setCurrentKeyword] = useState('');
  const [streamedContent, setStreamedContent] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/settings`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setLanguage(response.data.language);
      setBusinessInfo(response.data.businessInfo || '');
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };

  const handleKeywordKeyDown = (e) => {
    if ((e.key === 'Enter' || e.key === 'Tab') && currentKeyword.trim()) {
      e.preventDefault();
      setKeywords([...keywords, currentKeyword.trim()]);
      setCurrentKeyword('');
    }
  };

  const removeKeyword = (index) => {
    setKeywords(keywords.filter((_, i) => i !== index));
  };

  const handleGenerateBlog = async () => {
    setIsGenerating(true);
    setIsLoading(true);
    setStreamedContent('');
    setIsStreaming(true);

    try {
      const stream = await generateEnhancedBlogPost(
        businessInfo,
        mainTopic,
        audience,
        tone,
        length,
        language,
        keywords.join(', ')
      );

      let fullContent = '';
      for await (const chunk of stream) {
        const content = chunk.choices[0]?.delta?.content || '';
        fullContent += content;
        // Clean up the content
        const cleanedContent = fullContent
          .replace(/^```html\s*/, '')  // Remove ```html at the beginning
          .replace(/```\s*$/, '')  // Remove ``` at the end
          .replace(/^\s*<[^>]*>|<[^>]*>\s*$/g, '')  // Remove starting and ending HTML tags
          .replace(/\n+/g, '\n')  // Replace multiple newlines with a single newline
          .replace(/>\s+</g, '><')  // Remove whitespace between tags
          .replace(/\s+/g, ' ')  // Replace multiple spaces with a single space
          .trim();
        setStreamedContent(cleanedContent);
      }

      // Apply the same cleaning to the full content
      const cleanedFullContent = fullContent
        .replace(/^```html\s*/, '')
        .replace(/```\s*$/, '')
        .replace(/^\s*<[^>]*>|<[^>]*>\s*$/g, '')
        .replace(/\n+/g, '\n')
        .replace(/>\s+</g, '><')
        .replace(/\s+/g, ' ')
        .trim();
      setGeneratedBlog(cleanedFullContent);
    } catch (error) {
      console.error('Error generating blog post:', error);
    } finally {
      setIsLoading(false);
      setIsGenerating(false);
      setIsStreaming(false);
    }
  };

  const handleSaveBlog = async () => {
    setIsSaving(true);
    try {
      const token = localStorage.getItem('token');
      const today = new Date().toISOString().split('T')[0];
      
      // Convert HTML content to plain text while preserving structure
      const plainTextContent = generatedBlog
        .replace(/<h1>(.*?)<\/h1>/g, '$1\n\n')
        .replace(/<h2>(.*?)<\/h2>/g, '\n\n$1\n\n')
        .replace(/<h3>(.*?)<\/h3>/g, '\n$1\n')
        .replace(/<p>(.*?)<\/p>/g, '$1\n\n')
        .replace(/<ul>(.*?)<\/ul>/g, '$1\n')
        .replace(/<li>(.*?)<\/li>/g, '�� $1\n')
        .replace(/<strong>(.*?)<\/strong>/g, '*$1*')
        .replace(/<em>(.*?)<\/em>/g, '_$1_')
        .replace(/<[^>]+>/g, '')
        .replace(/&nbsp;/g, ' ')
        .replace(/\n{3,}/g, '\n\n')
        .trim();

      await axios.post(
        `${API_BASE_URL}/posts`,
        { 
          content: plainTextContent,
          generated_at: today,
          post_date: today,
          is_blog: true
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSaveMessage('Blog post saved successfully!');
    } catch (error) {
      console.error('Error saving blog post:', error);
      setSaveMessage('Error saving blog post. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="generate-post">
      <div className="generate-post-content">
        <div className="generate-form">
          <div className="card">
            <div className="scrollable-content">
              <div>
                <label htmlFor="mainTopic">Main Topic</label>
                <textarea
                  id="mainTopic"
                  value={mainTopic}
                  onChange={(e) => setMainTopic(e.target.value)}
                  placeholder="Enter the main topic for your blog post"
                  rows="4"
                />
              </div>
              <div>
                <label htmlFor="audience">Target Audience</label>
                <input 
                  id="audience" 
                  type="text" 
                  value={audience}
                  onChange={(e) => setAudience(e.target.value)}
                  placeholder="Describe your target audience" 
                />
              </div>
              <div className="input-group">
                <div>
                  <label htmlFor="tone">Tone</label>
                  <select id="tone" value={tone} onChange={(e) => setTone(e.target.value)}>
                    <option value="">Select a tone</option>
                    <option value="professional">Professional</option>
                    <option value="casual">Casual</option>
                    <option value="friendly">Friendly</option>
                    <option value="informative">Informative</option>
                    <option value="persuasive">Persuasive</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="length">Blog Length</label>
                  <select id="length" value={length} onChange={(e) => setLength(e.target.value)}>
                    <option value="">Select a length</option>
                    <option value="short">Short (250 words)</option>
                    <option value="medium">Medium (500 words)</option>
                    <option value="long">Long (1000 words)</option>
                  </select>
                </div>
              </div>
              
              <div>
                <label htmlFor="keywords">SEO Keywords (optional)</label>
                <div className="keywords-input-container">
                  <div className="keywords-list">
                    {keywords.map((keyword, index) => (
                      <span key={index} className="keyword">
                        {keyword}
                        <X size={12} onClick={() => removeKeyword(index)} />
                      </span>
                    ))}
                  </div>
                  <input
                    id="keywords"
                    type="text"
                    value={currentKeyword}
                    onChange={(e) => setCurrentKeyword(e.target.value)}
                    onKeyDown={handleKeywordKeyDown}
                    placeholder={keywords.length === 0 ? "Type a keyword and press Enter" : ""}
                  />
                </div>
              </div>
              
              <button 
                className={`generate-button ${isGenerating ? 'generating' : ''}`}
                onClick={handleGenerateBlog}
                disabled={isLoading || !mainTopic || !audience || !tone || !length}
              >
                <div className="content">
                  {isGenerating ? (
                    <>
                      <span>Generating Blog Post</span>
                      <div className="loading-dots">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </>
                  ) : (
                    'Generate Blog Post'
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="generated-post">
          <div className="card">
            <h3>Generated Blog Post:</h3>
            <div className="generated-post-content">
              {isStreaming || generatedBlog ? (
                <div 
                  dangerouslySetInnerHTML={{ 
                    __html: isStreaming ? streamedContent : generatedBlog 
                  }} 
                />
              ) : (
                <>
                  <span className="blinking-cursor">|</span>
                  Your generated blog post will appear here
                </>
              )}
            </div>
            {generatedBlog && (
              <div className="save-post-container">
                <button 
                  className="generate-button save-button full-width" 
                  onClick={handleSaveBlog}
                  disabled={isSaving}
                  style={{
                    backgroundColor: 'black',
                    color: 'white'
                  }}
                >
                  {isSaving ? 'Saving...' : 'Save Blog Post'}
                </button>
              </div>
            )}
            {saveMessage && <div className="save-message">{saveMessage}</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateBlog;