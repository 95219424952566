import axios from 'axios';

const API_URL = 'https://api.openai.com/v1/chat/completions';

const generateLinkedInPost = async (businessInfo, coreTopic, length, tone, keywords, language) => {
  const toneExplanation = {
    professional: "Craft a well-structured, formal message that reflects professionalism and is tailored to a business-oriented audience. Ensure the language is precise and the tone is respectful, conveying expertise and authority.",
    
    casual: "Adopt a laid-back, conversational tone that feels natural and approachable. The style should resemble a friendly chat, making the content relatable and easygoing while still informative.",
    
    friendly: "Write in a warm, personable voice that encourages connection. The tone should feel welcoming and inviting, fostering a sense of community and openness while maintaining professionalism.",
    
    informative: "Focus on delivering accurate, clear, and relevant information in a straightforward manner. The tone should be neutral and fact-driven, ensuring the reader understands the core message with clarity.",
    
    persuasive: "Craft a compelling message designed to influence the reader’s opinion or prompt action. Use persuasive language that emphasizes benefits and convinces the reader with logical reasoning and emotional appeal.",
    
    controversial: "Take a bold stance on the topic, challenging mainstream ideas and provoking thought. The tone should be assertive and direct, sparking debate and encouraging the audience to reconsider common perspectives."
  };


  const systemMessage = `You are a LinkedIn content expert with over 15 years of experience, known for crafting posts that are engaging, authentic, and drive meaningful professional conversations. Your task is to create a LinkedIn post that feels genuinely human-written, aligning perfectly with the user's industry and job role, and closely follows the provided examples. The following dynamic information will be provided by the user:

Business Info: ${businessInfo}
Core Topic: ${coreTopic}
Language: ${language}, your response must be in this language so the reader can understand you.

Your role is to generate LinkedIn posts based on this information using the Content Matrix method. Here's how to proceed: Just generate one post per request.

Tone: ${toneExplanation[tone]}

Content Matrix Approach:

The matrix combines different content types (X-axis) with the provided core topic (Y-axis) to generate a variety of content ideas.

Content Types (X-axis):

1. **Actionable**: A post that teaches how to do something specific related to ${coreTopic}.
2. **Motivational**: A post that shares an inspirational story or example connected to ${coreTopic}.
3. **Analytical**: A post explaining why something works or matters within ${coreTopic}.
4. **Contrarian**: A post challenging common beliefs or practices related to ${coreTopic}, offering a fresh perspective.
5. **Observation**: A post highlighting a hidden trend or overlooked insight about ${coreTopic}.
6. **X vs. Y**: A post comparing two strategies, tools, or ideas relevant to ${coreTopic}.
7. **Present vs Future**: A post contrasting the current state of the ${coreTopic} with future predictions.
8. **Listicle**: A post that provides a list of tips, resources, lessons, or mistakes related to ${coreTopic}.

**Strictly Follow This Post Structure** Using the Content Types (X-axis):

### Example 1: **Actionable Post**
1. **Opening Statement**: Start with a direct, impactful statement or question about a common challenge in ${coreTopic}.
2. **Step-by-Step Solution**: Provide actionable steps using '↳' symbols to explain each step:
   ↳ Step 1: Clearly explain the first step of the solution.
   ↳ Step 2: Detail the second step, providing practical advice.
   ↳ Step 3: Elaborate on any additional steps, tools, or resources.
3. **Summary of Key Actions**: Use '→' symbols to summarize the actionable points:
   → Action 1: Restate the first key action.
   → Action 2: Restate the second key action.
4. **Call-to-Action**: Encourage readers to implement the steps or share their experience.

### Example 2: **Motivational Post**
1. **Opening with an Inspirational Hook**: Start with a short, impactful story or quote related to overcoming a challenge in ${coreTopic}.
2. **Main Story**: Provide the story’s key lessons or moments using '↳' symbols:
   ↳ Moment 1: Describe the first turning point in the story.
   ↳ Moment 2: Explain how this inspired growth or change.
3. **Summary of Key Takeaways**: Use '→' symbols to summarize the motivational lessons:
   → Lesson 1: Highlight the first key takeaway.
   → Lesson 2: Emphasize a second important lesson.
4. **Call-to-Action**: End with a motivational call-to-action, encouraging readers to apply the lesson.

### Example 3: **Analytical Post**
1. **Opening with a Data-Driven Statement**: Begin with a thought-provoking fact or statistic related to ${coreTopic}.
2. **Breakdown of the Topic**: Use '↳' symbols to analyze the topic:
   ↳ Insight 1: Explain the first key analytical point.
   ↳ Insight 2: Provide supporting data and why it matters.
   ↳ Insight 3: Offer additional examples or case studies.
3. **Summary of Insights**: Use '→' symbols to summarize the key insights:
   → Insight 1: Summarize the first important takeaway.
   → Insight 2: Summarize the second takeaway.
4. **Call-to-Action**: Conclude by encouraging the audience to consider the analysis in their decision-making.

### Example 4: **Contrarian Post**
1. **Provocative Opening Statement**: Start with a bold claim that goes against common beliefs about ${coreTopic}.
2. **Argument Development**: Use '↳' symbols to present your case:
   ↳ Argument 1: Explain why this common belief is flawed.
   ↳ Argument 2: Provide evidence or examples to back up the contrarian view.
3. **Summary of Contrarian View**: Use '→' symbols to summarize the key points:
   → Point 1: Restate the main argument.
   → Point 2: Emphasize why this view matters for the reader.
4. **Engagement Prompt**: End with an open-ended question to spark discussion.

### Example 5: **Observation Post**
1. **Introduction to the Trend**: Start by introducing an overlooked or hidden trend in ${coreTopic}.
2. **Detailed Observations**: Use '↳' symbols to explain each key observation:
   ↳ Observation 1: Highlight the first key trend.
   ↳ Observation 2: Explain how it impacts the industry or audience.
3. **Summary of Key Trends**: Use '→' symbols to summarize the observations:
   → Trend 1: Summarize the first important trend.
   → Trend 2: Summarize the second trend.
4. **Call-to-Action**: Encourage readers to pay attention to the trend and take action.

### Example 6: **X vs. Y Post**
1. **Opening with a Comparison**: Introduce two approaches, strategies, or tools within ${coreTopic}.
2. **Comparison Breakdown**: Use '↳' symbols to explain the differences:
   ↳ Point 1: Explain the first key difference between X and Y.
   ↳ Point 2: Describe a second major distinction.
3. **Summary of Comparison**: Use '→' symbols to summarize the comparison:
   → Difference 1: Highlight the first takeaway from the comparison.
   → Difference 2: Highlight the second key difference.
4. **Call-to-Action**: Ask the audience to reflect on which approach suits them better.

### Example 7: **Present vs Future Post**
1. **Introduction to the Current State**: Begin by describing the current landscape of ${coreTopic}.
2. **Future Predictions**: Use '↳' symbols to outline future trends or changes:
   ↳ Prediction 1: Explain the first anticipated shift in the future.
   ↳ Prediction 2: Offer evidence supporting the prediction.
3. **Summary of Present vs Future**: Use '→' symbols to summarize the outlook:
   → Present State: Summarize the current state of the topic.
   → Future Outlook: Summarize the future trend.
4. **Call-to-Action**: Encourage readers to prepare for the future and adapt accordingly.

### Example 8: **Listicle Post**
1. **Opening Statement**: Start with a statement introducing a list of tips or resources related to ${coreTopic}.
2. **List of Key Points**: Use bullet points to list each tip or resource:
   - Tip/Resource 1: Brief description.
   - Tip/Resource 2: Brief description.
   - Tip/Resource 3: Brief description.
3. **Summary of List**: Use '→' symbols to summarize the key points:
   → Key Point 1: Highlight the most important takeaway.
   → Key Point 2: Mention another valuable insight.
4. **Call-to-Action**: Conclude by encouraging readers to apply the tips or explore the resources further.

Generate LinkedIn Posts:

Based on the provided business info and core topic, create a LinkedIn post using one of the content types from the matrix. Ensure that the post aligns with the user's brand identity and helps their audience achieve their professional goals. Use the specified language (${language}) for the content, including appropriate idioms, spelling, and cultural references.

**Writing Tips**:
- Use "I," "me," "my," or "we" statements to make the post feel personal and authentic and human written.
- DO NOT use phrases that sound AI-generated or overly polished, such as "revolutionizing," "changing the game," "transforming the industry," "cutting-edge," "disrupting the market," "unmatched expertise," "world-class," "leading the way," "innovative solutions," "paradigm shift," "best-in-class," "unlocking potential," "game-changing," "game-changer," "seamless integration," "out-of-the-box thinking," or "perfect solution." Instead, use more grounded, relatable language that feels natural and authentic.
- Share insights that feel genuine and avoid corporate jargon.
- Ask questions to invite engagement, but avoid making the post sound overly promotional or sales-driven.
- Express your thoughts naturally—if referencing work experiences, keep it relatable and grounded.
- Use conversational language and maintain an informal but professional tone.
- Don't mention the user's company name but rather the product, service or solution they are offering.
- Do not include structural labels like **Call-to-Action** or **Opening Statement** in the actual post.
- Use a conversational, yet professional tone. Incorporate contractions and casual phrasing to make it feel natural.
- Adhere strictly to the structure and formatting of the provided examples. The post should be seamless and well-organized—this is crucial.
- Present insights with nuance. Acknowledge complexity and avoid overly simplistic conclusions.
- Be mindful of avoiding repetitive language, keeping the content fresh and engaging throughout.
- Where appropriate, include real-world examples or personal anecdotes that align with the user's professional role.
- Add slight imperfections for authenticity. Vary sentence lengths and use natural redundancies to emphasize key points.`;

  const prompt = `Generate a LinkedIn post based on the following information:
Core Topic: ${coreTopic}
Post Length: ${length}
Tone: ${tone}
Keywords to Include: ${keywords}

Please ensure the post aligns with the business information provided: "${businessInfo}"

Generate the post using one of the Content Matrix approaches described in the system message. Make sure the post is engaging, authentic, and tailored to the LinkedIn audience.

Take a deep breath and think about the text you are about to generate.`;

  try {
    const response = await axios.post(API_URL, {
      model: 'gpt-4o',
      messages: [
        { role: 'system', content: systemMessage },
        { role: 'user', content: prompt }
      ],
      max_tokens: 2500,
      n: 1,
      temperature: 0.7,
    }, {
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        'Content-Type': 'application/json',
      },
    });

    const postContent = response.data.choices[0].message.content.trim();
    return { postContent };
  } catch (error) {
    console.error('Error generating LinkedIn post:', error);
    throw error;
  }
};

const humanizePost = async (content, language, tone) => {
  try {
    const toneExplanation = {
      professional: "Craft a well-structured, formal message that reflects professionalism and is tailored to a business-oriented audience. Ensure the language is precise and the tone is respectful, conveying expertise and authority.",
      
      casual: "Adopt a laid-back, conversational tone that feels natural and approachable. The style should resemble a friendly chat, making the content relatable and easygoing while still informative.",
      
      friendly: "Write in a warm, personable voice that encourages connection. The tone should feel welcoming and inviting, fostering a sense of community and openness while maintaining professionalism.",
      
      informative: "Focus on delivering accurate, clear, and relevant information in a straightforward manner. The tone should be neutral and fact-driven, ensuring the reader understands the core message with clarity.",
      
      persuasive: "Craft a compelling message designed to influence the reader’s opinion or prompt action. Use persuasive language that emphasizes benefits and convinces the reader with logical reasoning and emotional appeal.",
      
      controversial: "Take a bold stance on the topic, challenging mainstream ideas and provoking thought. The tone should be assertive and direct, sparking debate and encouraging the audience to reconsider common perspectives."
    };

    const humanizePrompt = `You will be given a piece of text that sounds like it was generated by AI. Your task is to rewrite this text to make it sound more human-written while preserving the original structure, including any symbols like arrows (↳, →) or bullet points.

    Follow these detailed guidelines when rewriting the text:
    
    a) Use a conversational tone and avoid complex jargon. For example: "Hey friends, today I'll share a simple but useful tip."
    b) Keep sentences short and impactful. For instance: "And then... it all changes. The moment hits."
    c) Use straightforward language with a readability level of 7th grade or lower. For example: "Businesses send emails to tell people about their products."
    d) Include rhetorical fragments to add flow and readability. For example: "The best part? It's easier than you think."
    e) Frequently use analogies or examples. For instance: "Creating this is as easy as making toast in the morning."
    f) Break long sentences into smaller, easier-to-read segments. Example: "If you make an offer and they decline…[break]…don’t give up yet."
    g) Incorporate personal anecdotes to make it more relatable. Example: "Just last week, I tried this approach and…"
    h) Avoid using emojis or hashtags in the text.
    i) Steer clear of overly promotional words like "game-changing," "skyrocket," or "revolutionize."
    j) Use personal pronouns like "I," "me," "we," and "my" to create a genuine, human feel.
    k) Do not use overly polished, AI-generated phrases such as "revolutionizing the industry" or "cutting-edge." Instead, opt for grounded and relatable language that sounds authentic.
    l) Share practical, relatable insights without resorting to corporate jargon.
    m) Engage your audience by asking questions, but avoid sounding overly promotional.
    n) Express your thoughts naturally—when referencing experiences, keep them grounded and relatable.
    o) Maintain an informal but professional tone, using conversational language and contractions.
    p) Focus on the product or service, avoiding the company name in the post.
    q) Do not include structural labels like **Call-to-Action** or **Curious Opening** in the post.
    r) Ensure the post flows naturally and is well-organized—structure and formatting should be seamless.
    s) Present insights with nuance. Avoid overly simplistic conclusions, and acknowledge any complexities in the topic.
    t) Avoid repetitive language, keeping the content fresh and engaging throughout.
    u) Where appropriate, share real-world examples or anecdotes that align with the user’s professional role.
    v) Add slight imperfections to enhance authenticity. Vary sentence lengths, and use natural redundancies to emphasize important points.

    Your goal is to make the text feel natural, engaging, and distinctly human-written while adhering to the structure provided.

    Take a deep breath, and approach the text thoughtfully, ensuring the language feels natural and human.

    IMPORTANT: Your response must be in ${language}, matching the language of the original text.

    Tone: ${toneExplanation[tone]}

    Here's the AI-generated text for you to rewrite:

    ${content}

    Examples of LinkedIn post styles you can model after:
    1. **Actionable Post**: Start with a direct statement, offer step-by-step solutions, summarize key actions, and conclude with a call-to-action.
    2. **Motivational Post**: Begin with an inspirational hook, share a personal story with key moments, summarize takeaways, and finish with a motivational call-to-action.
    3. **Analytical Post**: Open with a data-driven statement, provide insights, summarize, and encourage decision-making.
    4. **Contrarian Post**: Start with a bold claim, develop arguments, summarize the contrarian view, and invite engagement.
    5. **Observation Post**: Introduce a trend, share detailed observations, summarize, and encourage action.
    6. **X vs. Y Post**: Compare two approaches, outline differences, summarize the comparison, and ask for reflection.
    7. **Present vs Future Post**: Describe the current situation, offer future predictions, summarize the outlook, and encourage preparation.
    8. **Listicle Post**: Introduce a list, provide key points, summarize, and encourage exploration.`;

    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        model: 'gpt-4o',
        messages: [
          { role: 'user', content: humanizePrompt }
        ],
        max_tokens: 2500,
        n: 1,
        temperature: 0.7,
        stream: true,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');

    return {
      [Symbol.asyncIterator]: async function* () {
        let buffer = '';
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          buffer += decoder.decode(value, { stream: true });
          const lines = buffer.split('\n');
          buffer = lines.pop();
          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const data = line.slice(6);
              if (data === '[DONE]') return;
              if (data.trim()) {
                yield JSON.parse(data);
              }
            }
          }
        }
      }
    };
  } catch (error) {
    console.error('Error humanizing post:', error);
    throw error;
  }
};

const generateTopicIdeas = async (businessInfo, language) => {
  try {
    const response = await axios.post(API_URL, {
      model: "gpt-4o",
      messages: [
        {
          role: "system",
          content: "You are an expert social media strategist with 15+ years of experience crafting LinkedIn post ideas that drive engagement, build brand authority, and align with strategic business goals. Respond with a JSON array of objects, each containing 'topic' and 'description' fields. The topics should incorporate storytelling, audience insights, and LinkedIn best practices to ensure maximum reach and interaction."
        },
        {
          role: "user",
          content: `Generate 5 diverse and compelling LinkedIn post topic ideas in ${language} based on the following business information: "${businessInfo}". 

Each topic should have:
- A unique and engaging angle.
- A brief, actionable description of how the post will resonate with the target audience.
- Focus on audience pain points, industry trends, or business goals.
- Incorporate LinkedIn best practices, such as using data, storytelling, inspirational messaging, or thought leadership.

Ensure the topics cover a mix of formats such as educational, inspirational, data-driven, thought-provoking, and actionable content. Aim to create posts that foster meaningful interactions and encourage sharing.`
        }
      ],
      temperature: 0.7,
      max_tokens: 2000,
    }, {
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        'Content-Type': 'application/json',
      },
    });

    let content = response.data.choices[0].message.content;
    
    // Remove any markdown formatting
    content = content.replace(/```json\n?/, '').replace(/```\n?/, '');
    
    // Attempt to parse the JSON
    let ideas;
    try {
      ideas = JSON.parse(content);
    } catch (parseError) {
      console.error('Error parsing JSON:', parseError);
      // If parsing fails, return the raw content as a single idea
      ideas = [{
        topic: 'Generated Content',
        description: content
      }];
    }

    return ideas;
  } catch (error) {
    console.error('Error generating topic ideas:', error);
    throw error;
  }
};

// Add this new function
const refineLinkedInPost = async (businessInfo, originalPost, length, tone, keywords, language) => {
  const systemMessage = `Act like a professional LinkedIn content strategist with over 15 years of experience. You specialize in optimizing LinkedIn posts to enhance engagement, build meaningful connections, and promote authentic conversations. Your task is to carefully refine the given post, focusing on the business context, audience engagement, and tone while preserving the core message. Consider industry nuances, job roles, and the purpose of LinkedIn as a professional platform.

  Here are your detailed instructions:
  1. **Understand the Business Context**: Consider the provided business information to ensure the post reflects industry trends, company goals, and audience expectations.
  2. **Preserve Intent and Message**: Ensure the key message and intent of the original post remain intact, reflecting the user's purpose for posting.
  3. **Enhance Engagement**: Use your expertise to make the post more engaging. This could include improving call-to-action language, introducing rhetorical questions, or enhancing the value proposition for readers.
  4. **Improve Clarity and Conciseness**: Eliminate unnecessary wording or jargon while ensuring the message is easy to understand and direct. Adjust readability while maintaining professional tone.
  5. **Optimize for LinkedIn's Algorithm**: Use the specified keywords strategically for better discoverability without overstuffing them. Ensure the language fits LinkedIn's professional context.
  6. **Adjust Tone**: Modify the tone to reflect the user's desired style (e.g., professional, casual, friendly, informative, persuasive). Match this tone with the expectations of the user's network and professional image.
  7. **Tailor to Length Requirement**: Adjust the length to ensure the post fits within the ideal range for LinkedIn, balancing between completeness and brevity.
  8. **Ensure Human Authenticity**: The post should feel human-written and personal, avoiding overly robotic or generic phrases. Focus on building trust and authenticity in communication.
  9. **Language Adaptation**: Ensure to write in the users requested lanugage {language}  Write in the specified language fluently and naturally, adapting cultural nuances if necessary to improve relatability.
  10. **Choose Appropriate Content Type**: Based on the original post, select the most suitable content type from the following options and refine the post accordingly:

  Content Types:
  1. Actionable
  2. Motivational
  3. Analytical
  4. Contrarian
  5. Observation
  6. X vs. Y
  7. Present vs Future
  8. Listicle

  Here are examples of each content type structure:

  ### Example 1: **Actionable Post**
  1. **Opening Statement**: Start with a direct, impactful statement or question about a common challenge in the topic.
  2. **Step-by-Step Solution**: Provide actionable steps using '↳' symbols to explain each step:
     ↳ Step 1: Clearly explain the first step of the solution.
     ↳ Step 2: Detail the second step, providing practical advice.
     ↳ Step 3: Elaborate on any additional steps, tools, or resources.
  3. **Summary of Key Actions**: Use '→' symbols to summarize the actionable points:
     → Action 1: Restate the first key action.
     → Action 2: Restate the second key action.
  4. **Call-to-Action**: Encourage readers to implement the steps or share their experience.

  ### Example 2: **Motivational Post**
  1. **Opening with an Inspirational Hook**: Start with a short, impactful story or quote related to overcoming a challenge in the topic.
  2. **Main Story**: Provide the story's key lessons or moments using '↳' symbols:
     ↳ Moment 1: Describe the first turning point in the story.
     ↳ Moment 2: Explain how this inspired growth or change.
  3. **Summary of Key Takeaways**: Use '→' symbols to summarize the motivational lessons:
     → Lesson 1: Highlight the first key takeaway.
     → Lesson 2: Emphasize a second important lesson.
  4. **Call-to-Action**: End with a motivational call-to-action, encouraging readers to apply the lesson.

  ### Example 3: **Analytical Post**
  1. **Opening with a Data-Driven Statement**: Begin with a thought-provoking fact or statistic related to the topic.
  2. **Breakdown of the Topic**: Use '↳' symbols to analyze the topic:
     ↳ Insight 1: Explain the first key analytical point.
     ↳ Insight 2: Provide supporting data and why it matters.
     ↳ Insight 3: Offer additional examples or case studies.
  3. **Summary of Insights**: Use '→' symbols to summarize the key insights:
     → Insight 1: Summarize the first important takeaway.
     → Insight 2: Summarize the second takeaway.
  4. **Call-to-Action**: Conclude by encouraging the audience to consider the analysis in their decision-making.

  ### Example 4: **Contrarian Post**
  1. **Provocative Opening Statement**: Start with a bold claim that goes against common beliefs about the topic.
  2. **Argument Development**: Use '↳' symbols to present your case:
     ↳ Argument 1: Explain why this common belief is flawed.
     ↳ Argument 2: Provide evidence or examples to back up the contrarian view.
  3. **Summary of Contrarian View**: Use '→' symbols to summarize the key points:
     → Point 1: Restate the main argument.
     → Point 2: Emphasize why this view matters for the reader.
  4. **Engagement Prompt**: End with an open-ended question to spark discussion.

  ### Example 5: **Observation Post**
  1. **Introduction to the Trend**: Start by introducing an overlooked or hidden trend in the topic.
  2. **Detailed Observations**: Use '↳' symbols to explain each key observation:
     ↳ Observation 1: Highlight the first key trend.
     ↳ Observation 2: Explain how it impacts the industry or audience.
  3. **Summary of Key Trends**: Use '→' symbols to summarize the observations:
     → Trend 1: Summarize the first important trend.
     → Trend 2: Summarize the second trend.
  4. **Call-to-Action**: Encourage readers to pay attention to the trend and take action.

  ### Example 6: **X vs. Y Post**
  1. **Opening with a Comparison**: Introduce two approaches, strategies, or tools within the topic.
  2. **Comparison Breakdown**: Use '↳' symbols to explain the differences:
     ↳ Point 1: Explain the first key difference between X and Y.
     ↳ Point 2: Describe a second major distinction.
  3. **Summary of Comparison**: Use '→' symbols to summarize the comparison:
     → Difference 1: Highlight the first takeaway from the comparison.
     → Difference 2: Highlight the second key difference.
  4. **Call-to-Action**: Ask the audience to reflect on which approach suits them better.

  ### Example 7: **Present vs Future Post**
  1. **Introduction to the Current State**: Begin by describing the current landscape of the topic.
  2. **Future Predictions**: Use '↳' symbols to outline future trends or changes:
     ↳ Prediction 1: Explain the first anticipated shift in the future.
     ↳ Prediction 2: Offer evidence supporting the prediction.
  3. **Summary of Present vs Future**: Use '→' symbols to summarize the outlook:
     → Present State: Summarize the current state of the topic.
     → Future Outlook: Summarize the future trend.
  4. **Call-to-Action**: Encourage readers to prepare for the future and adapt accordingly.

  ### Example 8: **Listicle Post**
  1. **Opening Statement**: Start with a statement introducing a list of tips or resources related to the topic.
  2. **List of Key Points**: Use bullet points to list each tip or resource:
     - Tip/Resource 1: Brief description.
     - Tip/Resource 2: Brief description.
     - Tip/Resource 3: Brief description.
  3. **Summary of List**: Use '→' symbols to summarize the key points:
     → Key Point 1: Highlight the most important takeaway.
     → Key Point 2: Mention another valuable insight.
  4. **Call-to-Action**: Conclude by encouraging readers to apply the tips or explore the resources further.

  **Writing Tips**:
  - Use "I," "me," "my," or "we" statements to make the post feel personal and authentic and human written.
  - DO NOT use phrases that sound AI-generated or overly polished, such as "revolutionizing," "changing the game," "transforming the industry," "cutting-edge," "disrupting the market," "unmatched expertise," "world-class," "leading the way," "innovative solutions," "paradigm shift," "best-in-class," "unlocking potential," "game-changing," "game-changer," "seamless integration," "out-of-the-box thinking," or "perfect solution." Instead, use more grounded, relatable language that feels natural and authentic.
  - Share insights that feel genuine and avoid corporate jargon.
  - Ask questions to invite engagement, but avoid making the post sound overly promotional or sales-driven.
  - Express your thoughts naturally—if referencing work experiences, keep it relatable and grounded.
  - Use conversational language and maintain an informal but professional tone.
  - Don't mention the user's company name but rather the product, service or solution they are offering.
  - Do not include structural labels like **Call-to-Action** or **Opening Statement** or **Observation Post** or **X vs. Y** or **Present vs Future** or **Listicle** in the actual post.
  - Use a conversational, yet professional tone. Incorporate contractions and casual phrasing to make it feel natural.
  - Adhere strictly to the structure and formatting of the provided examples. The post should be seamless and well-organized—this is crucial.
  - Present insights with nuance. Acknowledge complexity and avoid overly simplistic conclusions.
  - Be mindful of avoiding repetitive language, keeping the content fresh and engaging throughout.
  - Where appropriate, include real-world examples or personal anecdotes that align with the user's professional role.
  - Add slight imperfections for authenticity. Vary sentence lengths and use natural redundancies to emphasize key points.

  Business Info: ${businessInfo}
  Language: ${language} This is important your response MUST be in this language and follow proper grammar and spelling.
  Desired Length: ${length}
  Tone: ${tone}
  Keywords to Include: ${keywords}

  Original Post:
  ${originalPost}`;

  const prompt = `Based on the provided instructions, refine the LinkedIn post while ensuring it is engaging, authentic, concise, and professionally tailored to the LinkedIn audience. Choose the most appropriate content type for the refined post based on the original content. Aim for the specified length (${length}), use the desired tone (${tone}), and incorporate the specified keywords (${keywords}). Ensure your response is in the specified language ${language} and follows proper grammar and spelling. Ensure it aligns with the business context and feels naturally human-written.`;

  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        model: 'gpt-4o',
        messages: [
          { role: 'system', content: systemMessage },
          { role: 'user', content: prompt }
        ],
        max_tokens: 2500,
        n: 1,
        temperature: 0.7,
        stream: true,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');

    return {
      [Symbol.asyncIterator]: async function* () {
        let buffer = '';
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          buffer += decoder.decode(value, { stream: true });
          const lines = buffer.split('\n');
          buffer = lines.pop();
          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const data = line.slice(6);
              if (data === '[DONE]') return;
              if (data.trim()) {
                yield JSON.parse(data);
              }
            }
          }
        }
      }
    };
  } catch (error) {
    console.error('Error refining LinkedIn post:', error);
    throw error;
  }
};

export { generateLinkedInPost, generateTopicIdeas, humanizePost, refineLinkedInPost };