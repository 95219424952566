import React, { useState, useEffect } from 'react';
import { generateLinkedInPost, generateTopicIdeas, humanizePost, refineLinkedInPost } from '../services/openai';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { Mic, MicOff, ChevronDown, ChevronUp, ToggleLeft, ToggleRight } from 'lucide-react'; // Import icons

function GeneratePost() {
  const [coreTopic, setCoreTopic] = useState('');
  const [length, setLength] = useState('');
  const [tone, setTone] = useState('');
  const [keywords, setKeywords] = useState('');
  const [generatedPost, setGeneratedPost] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [language, setLanguage] = useState('EN/US');
  const [isGenerating, setIsGenerating] = useState(false);
  const [saveMessage, setSaveMessage] = useState('');
  const [streamedContent, setStreamedContent] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [postDate, setPostDate] = useState(new Date().toISOString().split('T')[0]);
  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [activeInput, setActiveInput] = useState(null);
  const [topicIdeas, setTopicIdeas] = useState([]);
  const [expandedTopicIndex, setExpandedTopicIndex] = useState(null);
  const [isGeneratingTopics, setIsGeneratingTopics] = useState(false);
  const [businessInfo, setBusinessInfo] = useState('');
  const [isHumanizing, setIsHumanizing] = useState(false);
  const [isRefineMode, setIsRefineMode] = useState(false);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/settings`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setLanguage(response.data.language);
      setBusinessInfo(response.data.businessInfo || '');
      initializeSpeechRecognition(response.data.language);
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };

  const initializeSpeechRecognition = (lang = 'EN/US') => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = getLanguageCode(lang);

      recognition.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map(result => result[0].transcript)
          .join('');
        
        switch(activeInput) {
          case 'coreTopic':
            setCoreTopic(prevTopic => prevTopic + ' ' + transcript);
            break;
          default:
            break;
        }
      };

      setRecognition(recognition);
    }
  };

  const getLanguageCode = (lang) => {
    const languageCodes = {
      'EN/US': 'en-US',
      'EN/GB': 'en-GB',
      'FR/FR': 'fr-FR',
      'DE/DE': 'de-DE',
      'IT/IT': 'it-IT',
      'ES/ES': 'es-ES',
    };
    return languageCodes[lang] || 'en-US';
  };

  const handleGeneratePost = async () => {
    setIsGenerating(true);
    setIsLoading(true);
    setStreamedContent('');
    setIsStreaming(false);
    setGeneratedPost('');
    setIsHumanizing(false);

    try {
      let postStream;

      if (isRefineMode) {
        postStream = await refineLinkedInPost(
          businessInfo,
          coreTopic,
          length,
          tone,
          keywords,
          language
        );
      } else {
        const { postContent } = await generateLinkedInPost(
          businessInfo,
          coreTopic,
          length,
          tone,
          keywords,
          language
        );
        setIsHumanizing(true);
        postStream = await humanizePost(postContent, language);
      }

      // Stream the content
      setIsStreaming(true);
      let fullContent = '';
      for await (const chunk of postStream) {
        const content = chunk.choices[0]?.delta?.content || '';
        fullContent += content;
        setStreamedContent(fullContent);
      }

      setGeneratedPost(fullContent);
    } catch (error) {
      console.error('Error generating post:', error);
    } finally {
      setIsLoading(false);
      setIsGenerating(false);
      setIsHumanizing(false);
      setIsStreaming(false);
    }
  };

  const handleSavePost = async () => {
    setIsSaving(true);
    try {
      const token = localStorage.getItem('token');
      const today = new Date().toISOString().split('T')[0]; // Get today's date
      await axios.post(
        `${API_BASE_URL}/posts`,
        { 
          content: generatedPost, 
          generated_at: today, // Use generated_at for today's date
          post_date: postDate // Use post_date for the scheduled date from the date picker
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSaveMessage('Post saved successfully!');
    } catch (error) {
      console.error('Error saving post:', error);
      setSaveMessage('Error saving post. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const toggleListening = (inputField) => {
    if (isListening && activeInput === inputField) {
      recognition.stop();
      setIsListening(false);
      setActiveInput(null);
    } else {
      if (isListening) {
        recognition.stop();
      }
      setActiveInput(inputField);
      recognition.start();
      setIsListening(true);
    }
  };

  const toggleTopicExpansion = (index) => {
    setExpandedTopicIndex(prevIndex => prevIndex === index ? null : index);
  };

  const selectTopic = (topic) => {
    setCoreTopic(topic);
    setExpandedTopicIndex(null); // Close the expanded topic after selection
  };

  const handleGenerateTopicIdeas = async () => {
    setIsGeneratingTopics(true);
    try {
      const ideas = await generateTopicIdeas(businessInfo, language);
      setTopicIdeas(ideas.map(idea => ({ ...idea, expanded: false })));
    } catch (error) {
      console.error('Failed to generate topic ideas:', error);
    } finally {
      setIsGeneratingTopics(false);
    }
  };

  return (
    <div className="generate-post">
      <div className="generate-post-content">
        <div className="generate-form">
          <div className="card">
            <div className="scrollable-content">
              <div className="mode-toggle">
                <span>Generate Post</span>
                <button
                  className="toggle-button"
                  onClick={() => setIsRefineMode(!isRefineMode)}
                  aria-label={isRefineMode ? "Switch to Generate Post mode" : "Switch to Refine Post mode"}
                >
                  {isRefineMode ? <ToggleRight size={24} /> : <ToggleLeft size={24} />}
                </button>
                <span>Refine Post</span>
              </div>
              
              {!isRefineMode && (
                <button 
                  className="generate-button"
                  onClick={handleGenerateTopicIdeas}
                  disabled={isGeneratingTopics}
                >
                  <div className="content">
                    {isGeneratingTopics ? (
                      <>
                        <span>Generating Topics</span>
                        <div className="loading-dots">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </>
                    ) : (
                      'Generate Topic Ideas'
                    )}
                  </div>
                </button>
              )}
              
              {!isRefineMode && topicIdeas.length > 0 && (
                <div className="topic-ideas-container">
                  {topicIdeas.map((idea, index) => (
                    <div key={index} className="topic-idea-card">
                      <div 
                        className="topic-idea-header" 
                        onClick={() => toggleTopicExpansion(index)}
                      >
                        <span>{idea.topic}</span>
                        {expandedTopicIndex === index ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
                      </div>
                      {expandedTopicIndex === index && (
                        <div className="topic-idea-details">
                          <p>{idea.description}</p>
                          <button 
                            onClick={() => selectTopic(idea.topic)}
                            className="use-topic-button"
                          >
                            Use This Topic
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              
              {!isRefineMode && <div className="topic-ideas-separator"></div>}
              
              <div>
                <label htmlFor="coreTopic">{isRefineMode ? "Refine Post" : "Core Topic"}</label>
                <div className="input-with-icon">
                  <textarea
                    id="coreTopic"
                    value={coreTopic}
                    onChange={(e) => setCoreTopic(e.target.value)}
                    placeholder={isRefineMode ? "Add your post for refinement here" : "Enter the core topic for your post"}
                    rows="4"
                  />
                  {!isRefineMode && (
                    <button 
                      onClick={() => toggleListening('coreTopic')} 
                      className="icon-button"
                      title={isListening && activeInput === 'coreTopic' ? "Stop voice input" : "Start voice input"}
                    >
                      {isListening && activeInput === 'coreTopic' ? 
                        <MicOff size={18} style={{ color: 'red' }} /> : 
                        <Mic size={18} style={{ color: 'black' }} />
                      }
                    </button>
                  )}
                </div>
              </div>
              <div className="input-group">
                <div>
                  <label htmlFor="length">Post Length</label>
                  <select id="length" value={length} onChange={(e) => setLength(e.target.value)}>
                    <option value="">Select a length</option>
                    <option value="short">Short (50-100 words)</option>
                    <option value="medium">Medium (100-200 words)</option>
                    <option value="long">Long (200-300 words)</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="tone">Tone</label>
                  <select id="tone" value={tone} onChange={(e) => setTone(e.target.value)}>
                    <option value="">Select a tone</option>
                    <option value="professional">Professional</option>
                    <option value="casual">Casual</option>
                    <option value="friendly">Friendly</option>
                    <option value="informative">Informative</option>
                    <option value="persuasive">Persuasive</option>
                    <option value="controversial">Controversial</option>
                  </select>
                </div>
              </div>
              <div>
                <label htmlFor="keywords">Keywords or Phrases to Include (optional)</label>
                <input 
                  id="keywords" 
                  type="text" 
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                  placeholder="Enter keywords or phrases separated by commas" 
                />
              </div>
              
              <button 
                id="generate-post-button"
                className={`generate-button ${isGenerating ? 'generating' : ''}`}
                onClick={handleGeneratePost}
                disabled={isLoading || !coreTopic || !length || !tone}
              >
                <div className="content">
                  {isGenerating ? (
                    <>
                      <span>
                        {!isHumanizing
                          ? "Thinking"
                          : isHumanizing && !isStreaming
                          ? "Got a post idea, just polishing it like a human"
                          : "Crafting post"}
                      </span>
                      <div className="loading-dots">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </>
                  ) : (
                    isRefineMode ? 'Refine Post' : 'Generate Post'
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="generated-post">
          <div className="card">
            <h3>Generated LinkedIn Post:</h3>
            <div className="generated-post-content">
              {isStreaming ? (
                <>
                  {streamedContent}
                  <span className="blinking-cursor">|</span>
                </>
              ) : generatedPost ? (
                generatedPost
              ) : (
                <>
                  <span className="blinking-cursor">|</span>
                  Your generated post will appear here
                </>
              )}
            </div>
            {generatedPost && (
              <div className="save-post-container">
                <p className="schedule-instruction">Add to my calendar on:</p>
                <input
                  type="date"
                  value={postDate}
                  onChange={(e) => setPostDate(e.target.value)}
                  className="date-input full-width"
                />
                <button 
                  className="generate-button save-button full-width" 
                  onClick={handleSavePost}
                  disabled={isSaving}
                  style={{
                    backgroundColor: 'black',
                    color: 'white'
                  }}
                >
                  {isSaving ? 'Saving...' : 'Save Post'}
                </button>
              </div>
            )}
            {saveMessage && <div className="save-message">{saveMessage}</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneratePost;