import axios from 'axios';

const API_URL = 'https://api.openai.com/v1/chat/completions';

const generateEnhancedBlogPost = async (businessInfo, mainTopic, audience, tone, length, language, keywords) => {
    try {
      const wordCount = length === 'short' ? 250 : length === 'medium' ? 500 : 1000;
  
      const prompt = `
      Act like an expert content writer with 15 years of experience creating high-quality blog posts. You specialize in tailoring content to engage specific target audiences and drive SEO performance. Your goal is to create a blog post that is informative, engaging, and aligned with the business objectives.
  
      The blog post will be based on the following details:
      
      **Business Information:** ${businessInfo}
      **Main Topic:** ${mainTopic}
      **Target Audience:** ${audience}
      **Tone:** ${tone}
      **Target Word Count:** ${wordCount} words
      **Language:** ${language}
      **Keywords:** ${keywords}
      **Length:** ${length}
  
      ### Objective:
      Your objective is to write a blog post that educates, informs, and provides value to the target audience while reflecting the brand's tone. Ensure the post addresses the main topic in depth and is easy to read.
  
      ### Instructions:
      1. **Introduction**: Start with a compelling introduction that grabs the reader's attention and clearly introduces the main topic.
      
      2. **Main Content**: 
         - Break down the main topic into clear sections, using subheadings.
         - Provide detailed, well-researched information.
         - Where appropriate, use bullet points, numbered lists, or short paragraphs to maintain readability.
         - Include relevant examples, statistics, or real-world data to support your points. Search the internet for the information you need and add links to the data you use but do not make up your own data.
  
      3. **SEO**: 
         - Ensure the content is optimized for search engines by naturally integrating relevant keywords (related to the main topic).
         - The title should be SEO-friendly and include the primary keywords which are ${keywords}.
  
      4. **Tone & Style**:
         - Maintain a consistent tone as specified (e.g., professional, casual, conversational, etc.).
         - Ensure the language resonates with the target audience, avoiding jargon unless absolutely necessary.
  
      5. **Conclusion**: 
         - End the blog post with a strong conclusion that summarizes key points and provides a clear call to action (if applicable).
  
      ### Format:
      - Write the blog post in compact HTML format.
      - Use only one <h1> tag for the main title.
      - Use <h2> tags for main sections and <h3> tags for subsections if needed.
      - Use <p> tags for paragraphs, avoiding empty paragraphs.
      - Use <ul> or <ol> for lists, with <li> for list items.
      - Use <a> tags for citations and links.
      - Do not use <br> tags or multiple consecutive line breaks.
      - Do not add extra spaces or newlines between elements.
      - Ensure readability with concise paragraphs and bullet points where appropriate.
      

      ### Additional Notes:
      - If applicable, include data, statistics, or quotes with proper attribution.
      - Make sure the blog is informative and provides real value to the audience, answering potential questions they might have.
      - DO NOT use phrases that sound AI-generated or overly polished, such as "revolutionizing," "changing the game," "transforming the industry," "cutting-edge," "disrupting the market," "unmatched expertise," "world-class," "leading the way," "innovative solutions," "paradigm shift," "best-in-class," "unlocking potential," "game-changing," "game-changer," "seamless integration," "out-of-the-box thinking," or "perfect solution." Instead, use more grounded, relatable language that feels natural and authentic.
      - Do not include structural labels like ###Objective or ###Instructions or ###Format or ###Additional Notes in the actual post.
      - IMPORTANT: Ensure the length of the blog post is ${wordCount} words.
      - IMPORTANT: Ensure the blog post is written in ${language}.
      - IMPORTANT: Take time to research the internet for the information you need. You must add working link citations to all information you reference.
      - IMPORTANT: Ensure the blog post is SEO optimized by naturally integrating any specified keywords ${keywords}.
      
  Take a deep breath and work on this problem step-by-step.`;
  
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: 'gpt-4o',
          messages: [
            { role: 'system', content: 'You are a professional blog writer who creates well-researched, engaging, and SEO-optimized blog posts.' },
            { role: 'user', content: prompt }
          ],
          max_tokens: 4096, 
          n: 1,
          temperature: 0.7,
          stream: true,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      return {
        [Symbol.asyncIterator]: async function* () {
          let buffer = '';
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            buffer += decoder.decode(value, { stream: true });
            const lines = buffer.split('\n');
            buffer = lines.pop();
            for (const line of lines) {
              if (line.startsWith('data: ')) {
                const data = line.slice(6);
                if (data === '[DONE]') return;
                if (data.trim()) {
                  yield JSON.parse(data);
                }
              }
            }
          }
        }
      };
    } catch (error) {
      console.error('Error generating blog post:', error);
      throw error;
    }
  };
  
  export { generateEnhancedBlogPost };